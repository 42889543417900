<template>
  <v-card flat>
    <v-card-text>
      <hr class="mb-4" />
      <div class="d-flex warpper__contentHead">
        <h2>รายการจัดส่ง</h2>
        <!-- ตัวหนังสือของการเรียกดูไฟล์ -->
        <div class="d-flex align-center">
          <div class="wapperTextFile">
            <p class="me-5 mb-0 textFile" v-if="showTextFile">เรียกดูไฟล์</p>
            <input
              ref="refInputEl"
              type="file"
              accept=".jpeg,.png,.jpg,GIF"
              class="btn__uploadFile"
              v-if="showTextFile"
            />
          </div>
          <!-- ปุ่มกดดาวน์โหลด และ tooltip -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" value="download" @click="clickDownload">
                {{ textDownloads }}
                <v-progress-circular
                  :width="3"
                  color="green"
                  :size="20"
                  indeterminate
                  v-if="showTextFile"
                  class="spinner"
                ></v-progress-circular>
              </v-btn>
            </template>
            <span>1,000 รายการล่าสุด</span>
          </v-tooltip>
        </div>
      </div>
      <p>เครดิต ส่งฟรี {{ creditFree }} ชิ้น</p>
      <!-- ตารางสถานะต่าง ๆ -->
      <v-data-table :headers="headers" :items="desserts" :items-per-page="10" class="elevation-1"></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      textDownloads: 'ดาวน์โหลด',
      showTextFile: false,
      creditFree: 0,
      textSeemore: 'แสดงเพิ่มเติม',
      seemore: false,
      headers: [
        {
          text: 'สถานะ',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        { text: 'ชื่อผู้รับ', value: 'name' },
        { text: 'บิล', value: 'bill' },
        { text: 'รหัสติดตามพัสดุ', value: 'code' },
        { text: 'น้ำหนัก', value: 'weight' },
        { text: 'ค่าจัดส่ง', value: 'cost' },
      ],
      desserts: [
        {
          id: 'wdyw1',
          status: 'กำลังจัดส่ง',
          name: 'สมหมาย ใจดี',
          bill: '-',
          code: '110000007692',
          weight: '2 กิโลกรัม',
          cost: 50,
        },
        {
          id: 'wdyw2',
          status: 'จัดส่งสำเร็จ',
          name: 'สมศรี หมายจันทร์',
          bill: '-',
          code: '110000005890',
          weight: '1 กิโลกรัม',
          cost: 30,
        },
      ],
    }
  },
  methods: {
    clickDownload() {
      this.textDownloads = 'กำลังดาวน์โหลด'
      this.showTextFile = true
    },
    clickSeemore() {
      this.textSeemore = 'กำลังโหลดข้อมูล'
      this.seemore = true
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.warpper__contentHead {
  justify-content: space-between;
}
.wapperTextFile {
  position: relative;
}
.textFile {
  color: #569153;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.btn__uploadFile {
  background-color: red;
  position: absolute;
  width: 4.375rem;
  top: -50%;
  transform: translateY(50%);
  opacity: 0;
}
.spinner {
  margin-left: 0.5rem;
}
@media screen and (max-width: 600px) {
  .warpper__contentHead {
    display: grid !important;
    margin-bottom: 1rem;
  }
  .warpper__contentHead h2 {
    margin-bottom: 0.5rem;
  }
}
</style>
