<template>
  <v-card flat>
    <v-card-text>
      <hr class="mb-4" />
      <h2>
        ส่งสินค้ากับ whatyouwant shop ร่วมกับพาร์ทเนอร์ขนส่งชั้นนำ
      </h2>
      <div class="d-flex">
        <img
          src="https://s3-ap-southeast-1.amazonaws.com/assets.staging.page365.net/courier_providers/kerry.svg"
          alt="kerry"
        />
        <img
          src="https://s3-ap-southeast-1.amazonaws.com/assets.staging.page365.net/courier_providers/flash.jpeg"
          alt="flash"
        />
        <img
          src="https://s3-ap-southeast-1.amazonaws.com/assets.staging.page365.net/courier_providers/JT-Express.svg"
          alt="JT-Express"
        />
        <img
          src="https://s3-ap-southeast-1.amazonaws.com/assets.staging.page365.net/courier_providers/best-express-2.svg"
          alt="best-express"
        />
        <img
          src="https://s3-ap-southeast-1.amazonaws.com/assets.staging.page365.net/courier_providers/ninja-van.svg"
          alt="ninja-van"
        />
        <img
          src="https://s3-ap-southeast-1.amazonaws.com/assets.staging.page365.net/courier_providers/thai-post.png"
          alt="thai-post"
        />
      </div>
      <p class="text__description">
        พิมพ์ใบจ่าหน้าพร้อมเลขพัสดุ ข้อมูลผู้รับผู้ส่งเข้าระบบทันทีไม่มีตกหล่น
      </p>
      <h2>สร้างแบบฟอร์ม</h2>
      <p>กรุณากรอกข้อมูลให้ครบถ้วน <span class="text__Alert">*</span></p>
      <hr class="mt-4 mb-5" />
      <v-row>
        <!-- ผู้ให้บรอการส่งสินค้า -->
        <v-col
          md="4"
          cols="12"
        >
          <h3>ผู้ให้บริการส่งสินค้า</h3>
        </v-col>
        <v-col
          md="8"
          cols="12"
        >
          <v-select
            outlined
            dense
            hide-details
            label="กรุณาเลือก"
            :items="['Kerry Express', 'Flash Express', 'J&T Express', 'BEST Express', 'Ninjs Van', 'ไปรษณีไทย']"
            class="selected"
          ></v-select>
        </v-col>

        <!-- ชื่อรูปแบบการส่งสินค้า -->
        <v-col
          md="4"
          cols="12"
        >
          <h3>ชื่อรูปแบบการส่งสินค้า</h3>
        </v-col>
        <v-col
          md="8"
          cols="12"
        >
          <v-text-field
            v-model="typeTransit"
            :prepend-inner-icon="icons.mdiPencil"
            label="ชื่อรูปแบบการส่งสินค้า"
            outlined
            dense
            hide-details
            placeholder="ขนส่งสุดปัง"
            class="selected"
          ></v-text-field>
        </v-col>

        <!-- รูปแบบการคำนวณค่าส่ง -->
        <v-col
          md="4"
          cols="12"
        >
          <h3>รูปแบบการคำนวณ</h3>
        </v-col>
        <v-col
          md="8"
          cols="12"
        >
          <v-btn-toggle
            v-model="shipping"
            tile
            color="primary"
            group
          >
            <v-btn
              value="costDefault"
              class="toggle__switch"
              @click="clickShippingCostDefault"
            >
              คงที่
            </v-btn>

            <v-btn
              value="costQuantuty"
              class="toggle__switchOther"
              @click="clickShippingCostQuantuty"
            >
              ตามจำนวนสินค้า
            </v-btn>

            <v-btn
              value="costWeight"
              class="toggle__switchOther"
              @click="clickShippinCostWeight"
            >
              ตามน้ำหนักพัสดุ
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <!-- dynamic content -->
        <v-col
          v-if="shippingCostDefault"
          md="4"
          cols="12"
        >
          <h3>ค่าส่งสินค้า</h3>
        </v-col>
        <v-col
          v-if="shippingCostDefault"
          md="8"
          cols="12"
        >
          <v-text-field
            v-model="costTransit"
            :prepend-inner-icon="icons.mdiTag"
            label="ค่าส่งสินค้า (บาท)"
            outlined
            dense
            hide-details
            type="number"
            placeholder="0.00"
            class="mb-1 selected"
          ></v-text-field>
        </v-col>

        <!-- dynamic content -->
        <v-col
          v-if="shippingCostQuantity"
          md="4"
          cols="12"
        >
          <h3>ค่าส่งสินค้าเริ่มต้น</h3>
        </v-col>
        <v-col
          v-if="shippingCostQuantity"
          md="8"
          cols="12"
        >
          <v-text-field
            v-model="costTransitStart"
            :prepend-inner-icon="icons.mdiTag"
            label="ค่าส่งสินค้าเริ่มต้น (บาท)"
            outlined
            dense
            hide-details
            type="number"
            placeholder="0.00"
            class="mb-1 selected"
          ></v-text-field>
          <span>สำหรับสินค้าชิ้นแรก</span>
        </v-col>
        <v-col
          v-if="shippingCostQuantity"
          md="4"
          cols="12"
        >
          <h3>ค่าส่งสินค้าถัดไป</h3>
        </v-col>
        <v-col
          v-if="shippingCostQuantity"
          md="8"
          cols="12"
        >
          <v-text-field
            v-model="costTransitNext"
            :prepend-inner-icon="icons.mdiTag"
            label="ค่าส่งสินค้าถัดไป (บาท)"
            outlined
            dense
            hide-details
            type="number"
            placeholder="0.00"
            class="mb-1 selected"
          ></v-text-field>
          <span>สำหรับสินค้าชิ้นถัดไป</span>
        </v-col>

        <!-- dynamic content -->
        <v-col
          v-if="shippingCostWeight"
          md="4"
          cols="12"
        >
          <h3>ค่าส่งสินค้าเริ่มต้น</h3>
        </v-col>
        <v-col
          v-if="shippingCostWeight"
          md="8"
          cols="12"
        >
          <v-text-field
            v-model="costTransitWeightStart"
            :prepend-inner-icon="icons.mdiTag"
            label="ค่าส่งสินค้าเริ่มต้น (บาท)"
            outlined
            dense
            hide-details
            type="number"
            placeholder="0.00"
            class="mb-1 selected"
          ></v-text-field>
          <span>สำหรับน้ำหนัก 500 กรัมแรก</span>
        </v-col>
        <v-col
          v-if="shippingCostWeight"
          md="4"
          cols="12"
        >
          <h3>ค่าส่งสินค้าถัดไป</h3>
        </v-col>
        <v-col
          v-if="shippingCostWeight"
          md="8"
          cols="12"
        >
          <v-text-field
            v-model="costTransiWeighttNext"
            :prepend-inner-icon="icons.mdiTag"
            label="ค่าส่งสินค้าถัดไป (บาท)"
            outlined
            dense
            hide-details
            type="number"
            placeholder="0.00"
            class="mb-1 selected"
          ></v-text-field>
          <span>สำหรับน้ำหนัก 500 กรัมถัดไป</span>
        </v-col>

        <!-- การเก็บเงินค่าสินค้า -->
        <v-col
          md="4"
          cols="12"
        >
          <h3>การเก็บเงินค่าสินค้า</h3>
        </v-col>
        <v-col
          md="8"
          cols="12"
        >
          <v-btn-toggle
            v-model="text"
            tile
            color="primary"
            group
          >
            <v-btn
              value="default"
              class="toggle__switch"
              @click="clickCostDefault"
            >
              เก็บปกติ
            </v-btn>

            <v-btn
              value="cod"
              class="toggle__switch"
              @click="clickCostCOD"
            >
              เก็บปลายทาง COD
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <!-- dynamic content -->
        <v-col
          v-if="costTransitCOD"
          md="4"
          cols="12"
        >
          <h3>ช่องทางรับเงิน (COD)</h3>
        </v-col>
        <v-col
          v-if="costTransitCOD"
          md="8"
          cols="12"
        >
          <v-select
            outlined
            dense
            hide-details
            label="กรุณาเลือก"
            :items="['กรุณาเลือก', '+ เพิ่มช่องทางรับเงิน']"
            class="selected"
          ></v-select>
        </v-col>
        <v-col
          md="4"
          cols="12"
        >
          <h3>หน้าร้านออนไลน์</h3>
        </v-col>
        <v-col
          md="8"
          cols="12"
        >
          <v-checkbox
            v-model="checkbox"
            label="แสดงตัวเลือกนี้บน whatyouwantshop Store"
            class="mt-1 mb-3 text__checkbox"
            hide-details
          ></v-checkbox>
          <p class="margin__styleCustom">
            รูปแบบการจัดส่งนี้ไม่ได้กำหนดค่าส่งไว้ จึงไม่แนะนำให้แสดงตัวเลือกนี้บนหน้าร้าน
          </p>
        </v-col>
      </v-row>
      <div class="d-flex justify-end">
        <v-btn
          color="error"
          outlined
          class="me-2"
        >
          ยกเลิก
        </v-btn>
        <v-btn color="primary">
          สร้าง
        </v-btn>
      </div>
      <hr class="mt-5 mb-5" />

      <!-- การจัดส่งรูปแบบทั่วไป -->
      <div
        v-if="!switchContent"
        class="d-flex content__type"
      >
        <h2>การจัดส่งรูปแบบทั่วไป</h2>
        <v-btn
          color="primary"
          class="btn__responsive"
          @click="clickSwitchContent"
        >
          เพิ่มการจัดส่ง
        </v-btn>
      </div>
      <!-- <hr class="mt-3"> -->
      <div
        v-if="!switchContent"
        class="mt-5 warpper__contentTypeTransit"
      >
        <div>
          <v-icon size="50">
            {{ icons.mdiTruck }}
          </v-icon>
        </div>
        <p class="textHead">
          ไม่พบขนส่งรูปแบบทั่วไป
        </p>
        <p>
          รูปแบบทั่วไปไม่รองรับ ออกรหัสพัสดุอัตโนมัติ, เข้ารับสินค้าอัตโนมัติ, อัพเดตสถานะการจัดส่งอัตโนมัติ และ รับเงิน
          COD อัตโนมัติจากขนส่ง
        </p>
        <div>
          <v-btn
            color="primary"
            @click="clickSwitchContent"
          >
            + สร้างขนส่งรูปแบบทั่วไป
          </v-btn>
        </div>
      </div>
      <div v-if="switchContent">
        <h2>สร้างขนส่ง</h2>
        <p>กรุณากรอกข้อมูลให้ครบถ้วน <span class="text__Alert">*</span></p>
        <hr class="mt-3 mb-3" />
        <v-row>
          <!-- ผู้ให้บรอการส่งสินค้า -->
          <v-col
            md="4"
            cols="12"
          >
            <h3>ผู้ให้บริการส่งสินค้า</h3>
          </v-col>
          <v-col
            md="8"
            cols="12"
          >
            <v-select
              outlined
              dense
              hide-details
              label="กรุณาเลือก"
              :items="['Kerry Express', 'Flash Express', 'J&T Express', 'BEST Express', 'Ninjs Van', 'ไปรษณีไทย']"
              class="selected"
            ></v-select>
          </v-col>

          <!-- ชื่อรูปแบบการส่งสินค้า -->
          <v-col
            md="4"
            cols="12"
          >
            <h3>ชื่อรูปแบบการส่งสินค้า</h3>
          </v-col>
          <v-col
            md="8"
            cols="12"
          >
            <v-text-field
              v-model="typeTransit"
              :prepend-inner-icon="icons.mdiPencil"
              label="ชื่อรูปแบบการส่งสินค้า"
              outlined
              dense
              hide-details
              placeholder="ขนส่งสุดปัง"
              class="selected"
            ></v-text-field>
          </v-col>

          <v-col
            md="4"
            cols="12"
          >
            <h3>URL ติดตามพัสดุ</h3>
          </v-col>
          <v-col
            md="8"
            cols="12"
          >
            <v-text-field
              v-model="url"
              :prepend-inner-icon="icons.mdiLink"
              label="URL ติดตามพัสดุ"
              outlined
              dense
              hide-details
              placeholder="https://track.thailandpost.co.th/?trackNumber=[tracking_code]"
              class="selected"
            ></v-text-field>
          </v-col>

          <!-- รูปแบบการคำนวณค่าส่ง -->
          <v-col
            md="4"
            cols="12"
          >
            <h3>รูปแบบการคำนวณ</h3>
          </v-col>
          <v-col
            md="8"
            cols="12"
          >
            <v-btn-toggle
              v-model="shipping"
              tile
              color="primary"
              group
            >
              <v-btn
                value="costDefault"
                class="toggle__switch"
                @click="clickShippingCostDefault"
              >
                คงที่
              </v-btn>

              <v-btn
                value="costQuantuty"
                class="toggle__switchOther"
                @click="clickShippingCostQuantuty"
              >
                ตามจำนวนสินค้า
              </v-btn>

              <v-btn
                value="costWeight"
                class="toggle__switchOther"
                @click="clickShippinCostWeight"
              >
                ตามน้ำหนักพัสดุ
              </v-btn>
            </v-btn-toggle>
          </v-col>

          <!-- dynamic content -->
          <v-col
            v-if="shippingCostDefault"
            md="4"
            cols="12"
          >
            <h3>ค่าส่งสินค้า</h3>
          </v-col>
          <v-col
            v-if="shippingCostDefault"
            md="8"
            cols="12"
          >
            <v-text-field
              v-model="costTransit"
              :prepend-inner-icon="icons.mdiTag"
              label="ค่าส่งสินค้า (บาท)"
              outlined
              dense
              hide-details
              type="number"
              placeholder="0.00"
              class="mb-1 selected"
            ></v-text-field>
          </v-col>

          <!-- dynamic content -->
          <v-col
            v-if="shippingCostQuantity"
            md="4"
            cols="12"
          >
            <h3>ค่าส่งสินค้าเริ่มต้น</h3>
          </v-col>
          <v-col
            v-if="shippingCostQuantity"
            md="8"
            cols="12"
          >
            <v-text-field
              v-model="costTransitStart"
              :prepend-inner-icon="icons.mdiTag"
              label="ค่าส่งสินค้าเริ่มต้น (บาท)"
              outlined
              dense
              hide-details
              type="number"
              placeholder="0.00"
              class="mb-1 selected"
            ></v-text-field>
            <span>สำหรับสินค้าชิ้นแรก</span>
          </v-col>
          <v-col
            v-if="shippingCostQuantity"
            md="4"
            cols="12"
          >
            <h3>ค่าส่งสินค้าถัดไป</h3>
          </v-col>
          <v-col
            v-if="shippingCostQuantity"
            md="8"
            cols="12"
          >
            <v-text-field
              v-model="costTransitNext"
              :prepend-inner-icon="icons.mdiTag"
              label="ค่าส่งสินค้าถัดไป (บาท)"
              outlined
              dense
              hide-details
              type="number"
              placeholder="0.00"
              class="mb-1 selected"
            ></v-text-field>
            <span>สำหรับสินค้าชิ้นถัดไป</span>
          </v-col>

          <!-- dynamic content -->
          <v-col
            v-if="shippingCostWeight"
            md="4"
            cols="12"
          >
            <h3>ค่าส่งสินค้าเริ่มต้น</h3>
          </v-col>
          <v-col
            v-if="shippingCostWeight"
            md="8"
            cols="12"
          >
            <v-text-field
              v-model="costTransitWeightStart"
              :prepend-inner-icon="icons.mdiTag"
              label="ค่าส่งสินค้าเริ่มต้น (บาท)"
              outlined
              dense
              hide-details
              type="number"
              placeholder="0.00"
              class="mb-1 selected"
            ></v-text-field>
            <span>สำหรับน้ำหนัก 500 กรัมแรก</span>
          </v-col>
          <v-col
            v-if="shippingCostWeight"
            md="4"
            cols="12"
          >
            <h3>ค่าส่งสินค้าถัดไป</h3>
          </v-col>
          <v-col
            v-if="shippingCostWeight"
            md="8"
            cols="12"
          >
            <v-text-field
              v-model="costTransiWeighttNext"
              :prepend-inner-icon="icons.mdiTag"
              label="ค่าส่งสินค้าถัดไป (บาท)"
              outlined
              dense
              hide-details
              type="number"
              placeholder="0.00"
              class="mb-1 selected"
            ></v-text-field>
            <span>สำหรับน้ำหนัก 500 กรัมถัดไป</span>
          </v-col>

          <!-- การเก็บเงินค่าสินค้า -->
          <v-col
            md="4"
            cols="12"
          >
            <h3>การเก็บเงินค่าสินค้า</h3>
          </v-col>
          <v-col
            md="8"
            cols="12"
          >
            <v-btn-toggle
              v-model="text"
              tile
              color="primary"
              group
            >
              <v-btn
                value="default"
                class="toggle__switch"
                @click="clickCostDefault"
              >
                เก็บปกติ
              </v-btn>

              <v-btn
                value="cod"
                class="toggle__switch"
                @click="clickCostCOD"
              >
                เก็บปลายทาง COD
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <!-- dynamic content -->
          <v-col
            v-if="costTransitCOD"
            md="4"
            cols="12"
          >
            <h3>ช่องทางรับเงิน (COD)</h3>
          </v-col>
          <v-col
            v-if="costTransitCOD"
            md="8"
            cols="12"
          >
            <v-select
              outlined
              dense
              hide-details
              label="กรุณาเลือก"
              :items="['กรุณาเลือก', '+ เพิ่มช่องทางรับเงิน']"
              class="selected"
            ></v-select>
          </v-col>
          <v-col
            md="4"
            cols="12"
          >
            <h3>หน้าร้านออนไลน์</h3>
          </v-col>
          <v-col
            md="8"
            cols="12"
          >
            <v-checkbox
              v-model="checkbox"
              label="แสดงตัวเลือกนี้บน whatyouwantshop Store"
              class="mt-1 mb-3 text__checkbox"
              hide-details
            ></v-checkbox>
            <p class="margin__styleCustom">
              รูปแบบการจัดส่งนี้ไม่ได้กำหนดค่าส่งไว้ จึงไม่แนะนำให้แสดงตัวเลือกนี้บนหน้าร้าน
            </p>
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <v-btn
            color="error"
            outlined
            class="me-2"
          >
            ยกเลิก
          </v-btn>
          <v-btn color="primary">
            สร้าง
          </v-btn>
        </div>
      </div>
      <hr class="mt-5 mb-3" />
      <h2>ระยะเวลาจัดส่ง</h2>
      <p class="mt-2">
        ลูกค้าที่กำลังเลือกซื้อสินค้าจากหน้าร้าน จะมีความเชื่อมั่นมากขึ้นหากร้านค้าการันตีระยะเวลาในการแพ็กและจัดส่งของ
        สัญลักษณ์ที่คุณเลือกจะแสดงเครื่องหมายการันตีที่หน้าร้านของคุณ
      </p>
      <!-- TimeLine Content -->
      <!-- content1 -->
      <v-row>
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <div
            :class="{ content__timeline__active: click__timelineFirst }"
            class="content__timeline"
            @click="clickitemFirst"
          >
            <v-icon
              v-if="click__timelineFirst"
              class="icons__success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
            <p class="text_timeline">
              ไม่แสดงเครื่องหมาย
            </p>
          </div>
        </v-col>
        <!-- content2 -->
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <div
            :class="{ content__timeline__active: click__timelineTwo }"
            class="content__timeline"
            @click="clickitemTwo"
          >
            <v-icon
              v-if="click__timelineTwo"
              class="icons__success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
            <p class="text_timeline">
              ไม่แสดงเครื่องหมาย
            </p>
            <div class="content__inboxContent1">
              <v-icon
                size="50"
                class="icon_color"
              >
                {{ icons.mdiTruck }}
              </v-icon>
              <div class="text__inbox">
                <span class="font__top">Within</span>
                <span class="font__bottom">24 hrs.</span>
              </div>
            </div>
          </div>
        </v-col>
        <!-- content3 -->
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <div
            :class="{ content__timeline__active: click__timelineThree }"
            class="content__timeline"
            @click="clickitemThree"
          >
            <v-icon
              v-if="click__timelineThree"
              class="icons__success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
            <p class="text_timeline">
              ไม่แสดงเครื่องหมาย
            </p>
            <div class="content__inboxContent2">
              <v-icon
                size="50"
                class="icon_color"
              >
                {{ icons.mdiTruck }}
              </v-icon>
              <div class="text__inbox">
                <span class="font__top">Within</span>
                <span class="font__bottom">2-3 days</span>
              </div>
            </div>
          </div>
        </v-col>
        <!-- content4 -->
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <div
            :class="{ content__timeline__active: click__timelineFour }"
            class="content__timeline"
            @click="clickitemFour"
          >
            <v-icon
              v-if="click__timelineFour"
              class="icons__success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
            <p class="text_timeline">
              ไม่แสดงเครื่องหมาย
            </p>
            <div class="content__inboxContent3">
              <v-icon
                size="50"
                class="icon_color"
              >
                {{ icons.mdiTruck }}
              </v-icon>
              <div class="text__inbox">
                <span class="font__top">Within</span>
                <span class="font__bottom">5-7 days</span>
              </div>
            </div>
          </div>
        </v-col>
        <!-- content5 -->
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <div
            :class="{ content__timeline__active: click__tinelineFive }"
            class="content__timeline"
            @click="clickitemFive"
          >
            <v-icon
              v-if="click__tinelineFive"
              class="icons__success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
            <p class="text_timeline">
              ไม่แสดงเครื่องหมาย
            </p>
            <div class="content__inboxContent4">
              <v-icon
                size="50"
                class="icon_color"
              >
                {{ icons.mdiTruck }}
              </v-icon>
              <div class="text__inbox">
                <span class="font__top">Within</span>
                <span class="font__bottom">30 days</span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiPencil, mdiTag, mdiTruck, mdiLink, mdiCheckCircle,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiTag,
        mdiTruck,
        mdiLink,
        mdiCheckCircle,
      },
    }
  },
  data() {
    return {
      typeTransit: '',
      shipping: '',
      costTransit: '',
      text: '',
      checkbox: '',
      shippingCostDefault: true,
      shippingCostQuantity: false,
      shippingCostWeight: false,
      costTransitCOD: false,
      toggle_exclusive: undefined,
      switchContent: false,
      click__timelineFirst: true,
      click__timelineTwo: false,
      click__timelineThree: false,
      click__timelineFour: false,
      click__tinelineFive: false,
    }
  },
  methods: {
    clickShippingCostDefault() {
      this.shippingCostDefault = true
      this.shippingCostQuantity = false
      this.shippingCostWeight = false
    },
    clickShippingCostQuantuty() {
      this.shippingCostDefault = false
      this.shippingCostQuantity = true
      this.shippingCostWeight = false
    },
    clickShippinCostWeight() {
      this.shippingCostDefault = false
      this.shippingCostQuantity = false
      this.shippingCostWeight = true
    },
    clickCostCOD() {
      this.costTransitCOD = true
    },
    clickCostDefault() {
      this.costTransitCOD = false
    },
    clickSwitchContent() {
      this.switchContent = true
    },
    clickitemFirst() {
      this.click__timelineFirst = !this.click__timelineFirst
      this.click__timelineTwo = false
      this.click__timelineThree = false
      this.click__timelineFour = false
      this.click__tinelineFive = false
    },
    clickitemTwo() {
      this.click__timelineFirst = false
      this.click__timelineTwo = !this.click__timelineTwo
      this.click__timelineThree = false
      this.click__timelineFour = false
      this.click__tinelineFive = false
    },
    clickitemThree() {
      this.click__timelineFirst = false
      this.click__timelineTwo = false
      this.click__timelineThree = !this.click__timelineThree
      this.click__timelineFour = false
      this.click__tinelineFive = false
    },
    clickitemFour() {
      this.click__timelineFirst = false
      this.click__timelineTwo = false
      this.click__timelineThree = false
      this.click__timelineFour = !this.click__timelineFour
      this.click__tinelineFive = false
    },
    clickitemFive() {
      this.click__timelineFirst = false
      this.click__timelineTwo = false
      this.click__timelineThree = false
      this.click__timelineFour = false
      this.click__tinelineFive = !this.click__tinelineFive
    },
  },
}
</script>

<style scoped>
hr {
  /* border-color: #e5e5e5 !important; */
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
img {
  width: 3.75rem;
  height: 3.75rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 10px;
}
.text__description {
  background-color: #d1fae5;
  width: 26rem;
  border-radius: 100px;
  padding: 2px 2px 2px 10px;
  margin-top: 1rem;
  color: #746b7c !important;
}
.selected {
  width: 70%;
}
.btn__style {
  width: 100%;
}
.text__checkbox {
  font-weight: 700 !important;
}
.margin__styleCustom {
  margin-top: -10px;
}
.text__Alert {
  color: red;
}
.content__type {
  justify-content: space-between;
  align-content: center;
}
.warpper__contentTypeTransit {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.textHead {
  margin-bottom: 5px !important;
  font-size: 18px;
  font-weight: 600;
}
.toggle__switch {
  border-radius: 10px !important;
  border: none !important;
}
.toggle__switchOther {
  border-radius: 10px !important;
  border: none !important;
}
.content__timeline {
  /* background-color: aquamarine; */
  width: 300px;
  height: 70px;
  cursor: pointer;
  border: 2px solid #d4d3d4;
  border-radius: 10px;
  position: relative;
}
.content__timeline:hover {
  width: 300px;
  height: 70px;
  cursor: pointer;
  border: 2px solid #8dbc8a;
  border-radius: 10px;
  position: relative;
}
.content__timeline__active {
  width: 300px;
  height: 70px;
  cursor: pointer;
  border: 2px solid #8dbc8a;
  border-radius: 10px;
  position: relative;
}
.icons__success {
  position: absolute;
  color: #8dbc8a !important;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0.5rem;
}
.text_timeline {
  margin-left: 2.5rem;
  font-weight: 700;
  margin-top: 22px;
}
.content__inboxContent1 {
  background-color: red;
  border-radius: 10px;
  transform: translateY(-100%);
  width: 230px;
  margin-left: 2.5rem;
  display: flex;
}
.content__inboxContent2 {
  background-color: #f6ac32;
  border-radius: 10px;
  transform: translateY(-100%);
  width: 230px;
  margin-left: 2.5rem;
  display: flex;
}
.content__inboxContent3 {
  background-color: #4283d8;
  border-radius: 10px;
  transform: translateY(-100%);
  width: 230px;
  margin-left: 2.5rem;
  display: flex;
}
.content__inboxContent4 {
  background-color: #8f42af;
  border-radius: 10px;
  transform: translateY(-100%);
  width: 230px;
  margin-left: 2.5rem;
  display: flex;
}

.icon_color {
  color: #ffffff;
  margin-left: 1rem;
}
.text__inbox {
  display: grid;
  margin-left: 0.5rem;
}
.font__top {
  color: #ffffff;
  font-weight: 500;
}
.font__bottom {
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
}
@media screen and (max-width: 600px) {
  img {
    width: 2.75rem;
    height: 2.75rem;
  }
  .text__description {
    width: 19rem;
    border-radius: 10px;
  }
  .toggle__switchOther {
    width: 6.9rem;
    font-size: 14px !important;
  }
  .content__type {
    display: grid !important;
  }
  .btn__responsive {
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 960px) {
  .selected {
    width: 100%;
  }
}
</style>
