<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-breadcrumbs :items="items" class="pb-0">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="TransitPage">
        <TransitPage />
      </v-tab-item>

      <v-tab-item key="TransitPageSetting">
        <TransitPageSetting />
      </v-tab-item>

      <v-tab-item key="TransitPageHistory">
        <TransitPageHistory />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiTruckFast, mdiFileDocumentEdit, mdiBackupRestore } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import TransitPage from './TransitPage.vue'
import TransitPageSetting from './TransitPageSetting.vue'
import TransitPageHistory from './TransitPageHistory.vue'

export default {
  components: {
    TransitPage,
    TransitPageSetting,
    TransitPageHistory,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'การส่งสินค้า', icon: mdiTruckFast },
      { title: 'ตั้งค่าหน้ากระดาษ', icon: mdiFileDocumentEdit },
      { title: 'ประวัติการขนส่ง', icon: mdiBackupRestore },
    ]

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        username: 'whatyouwantshop',
        name: 'whatyouwantshop',
        email: 'whatyouwantshop@example.com',
        role: 'Admin',
        status: 'Active',
        company: 'Google.inc',
      },
      information: {
        bio: '',
        birthday: 'February 22, 1995',
        phone: '09461075555',
        website: 'https://whatyouwantshop.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
    }

    return {
      tab,
      tabs,
      accountSettingData,
      icons: {
        mdiTruckFast,
        mdiFileDocumentEdit,
        mdiBackupRestore,
      },
    }
  },
  data: () => ({
    items: [
      {
        text: 'สินค้า',
        disabled: false,
        href: 'product',
      },
      {
        text: 'การขนส่งสินค้า',
        disabled: true,
        href: 'transit',
      },
    ],
  }),
  methods: {
    clickitem() {
      this.clicktest = !this.clicktest
    },
  },
}
</script>

<style scoped></style>
