<template>
  <v-card flat>
    <v-card-text>
      <hr class="mb-4" />
      <h2 class="mb-2">ตั้งค่าหน้ากระดาษสำหรับการจ่าหน้าซอง</h2>
      <p>กรุณาเลือกขนาดที่คุณต้องการสำหรับพิมพ์จ่าหน้าซองจากหน้าจัดการบิล</p>
      <div class="d-flex">
        <div :class="{ content__timeline__active: sizeA4 }" class="content__timeline me-3" @click="clickSizeA4">
          <v-icon class="icons__success" v-if="sizeA4">{{ icons.mdiCheckCircle }}</v-icon>
          <div class="mt-2 warpper__content">
            <div class="square"></div>
            <div class="square"></div>
            <div class="square"></div>
            <!-- <div class="text__inbox">
            <span class="font__top">Within</span>
            <span class="font__bottom">24 hrs.</span>
          </div> -->
          </div>
          <span>A4</span>
          <p>พิมพ์ได้หลายจ่าหน้าต่อ 1 แผ่น</p>
        </div>
        <div :class="{ content__timeline__active: sizeA6 }" class="content__timeline" @click="clickSizeA6">
          <v-icon class="icons__success" v-if="sizeA6">{{ icons.mdiCheckCircle }}</v-icon>
          <div class="mt-2 warpper__content_vertical">
            <div class="square_vertical"></div>
            <div class="square_vertical"></div>
          </div>
          <span>A6</span>
          <p>พิมพ์ได้จ่าหน้าเดียวต่อ 1 แผ่น</p>
        </div>
      </div>
      <hr class="mb-4 mt-5" />
      <h2 class="mb-2">ตั้งค่ารายละเอียดบนหน้ากระดาษ</h2>
      <p class="mb-2">กรุณาเลือกรายละเอียดบนหน้ากระดาษที่ต้องการแสดง</p>
      <p class="font-weight-bold mb-1">ใบเสร็จรับเงิน</p>
      <v-row>
        <!-- รูปสินค้า -->
        <v-col md="6" sm="6" cols="12" class="reset__paddingBottom">
          <div class="content__receipt">
            <p>รูปสินค้า</p>
            <v-switch hide-details class="toggle__switch" v-model="photoProduct"></v-switch>
          </div>
        </v-col>
        <!-- ชื่อร้านค้า -->
        <v-col md="6" sm="6" cols="12" class="reset__paddingBottom">
          <div class="content__receipt">
            <p>ชื่อร้านค้า</p>
            <v-switch hide-details class="toggle__switch" v-model="name"></v-switch>
          </div>
        </v-col>
        <!-- ที่อยู่ร้าน -->
        <v-col md="6" sm="6" cols="12" class="reset__paddingBottom">
          <div class="content__receipt">
            <p>ที่อยู่ร้าน</p>
            <v-switch hide-details class="toggle__switch" v-model="address"></v-switch>
          </div>
        </v-col>
        <!-- เบอร์ร้าน -->
        <v-col md="6" sm="6" cols="12" class="reset__paddingBottom">
          <div class="content__receipt">
            <p>เบอร์ร้าน</p>
            <v-switch hide-details class="toggle__switch" v-model="phone"></v-switch>
          </div>
        </v-col>
        <!-- Email -->
        <v-col md="6" sm="6" cols="12" class="reset__paddingBottom">
          <div class="content__receipt">
            <p>อีเมลร้าน</p>
            <v-switch hide-details class="toggle__switch" v-model="email"></v-switch>
          </div>
        </v-col>
        <!-- link -->
        <v-col md="6" sm="6" cols="12" class="reset__paddingBottom">
          <div class="content__receipt">
            <p>ลิงก์หน้าร้าน</p>
            <v-switch hide-details class="toggle__switch" v-model="link"></v-switch>
          </div>
        </v-col>
        <!-- link FB -->
        <v-col md="6" sm="6" cols="12" class="reset__paddingBottom">
          <div class="content__receipt">
            <p>ลิงก์เฟซบุ๊กร้าน</p>
            <v-switch hide-details class="toggle__switch" v-model="linkFB"></v-switch>
          </div>
        </v-col>
        <!-- ที่อยู่ลูกค้า -->
        <v-col md="6" sm="6" cols="12" class="reset__paddingBottom">
          <div class="content__receipt">
            <p>ที่อยู่ลูกค้า</p>
            <v-switch hide-details class="toggle__switch" v-model="addressCustomer"></v-switch>
          </div>
        </v-col>
        <!-- บนใบเสร็จ -->
        <v-col md="6" sm="6" cols="12" class="reset__paddingBottom">
          <div class="content__receipt">
            <p>ใบจ่าหน้า (บนใบเสร็จรับเงิน)</p>
            <v-switch hide-details class="toggle__switch" v-model="topReceipt"></v-switch>
          </div>
        </v-col>
        <!-- แยกใบสุดท้าย -->
        <v-col md="6" sm="6" cols="12" class="reset__paddingBottom">
          <div class="content__receipt">
            <p>ใบจ่าหน้า (แยกใบสุดท้าย)</p>
            <v-switch hide-details class="toggle__switch" v-model="splitReceipt"></v-switch>
          </div>
        </v-col>
        <!-- ส่วนของใบจ่าหน้า -->
        <!-- ชื่อร้านค้า -->
        <v-col md="6" sm="6" cols="12" class="reset__paddingBottom">
          <p class="font-weight-bold mb-1">ใบจ่าหน้า</p>
          <div class="content__receipt">
            <p>ชื่อร้านค้า</p>
            <v-switch hide-details class="toggle__switch" v-model="nameOnLabel"></v-switch>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <!-- ส่วนของใบจ่าหน้า -->
        <!-- ชื่อร้านค้า -->
        <v-col md="6" sm="6" cols="12">
          <p class="font-weight-bold mb-1">ใบแพ็กของ</p>
          <div class="content__receipt">
            <p>รูปสินค้า</p>
            <v-switch hide-details class="toggle__switch" v-model="photoOnPackage"></v-switch>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheckCircle } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiCheckCircle,
      },
    }
  },
  data() {
    return {
      sizeA4: true,
      sizeA6: false,
      photoProduct: true,
      name: true,
      address: false,
      phone: true,
      email: false,
      link: true,
      linkFB: true,
      addressCustomer: false,
      topReceipt: true,
      splitReceipt: true,
      nameOnLabel: true,
      photoOnPackage: true,
    }
  },
  methods: {
    clickSizeA4() {
      this.sizeA4 = true
      this.sizeA6 = false
    },
    clickSizeA6() {
      this.sizeA4 = false
      this.sizeA6 = true
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.content__timeline {
  /* background-color: aquamarine; */
  width: 300px;
  height: 150px;
  cursor: pointer;
  border: 2px solid #d4d3d4;
  border-radius: 10px;
  /* position: relative; */
  display: grid;
  align-items: center;
  text-align: center;
}
.content__timeline:hover {
  width: 300px;
  height: 150px;
  cursor: pointer;
  border: 2px solid #8dbc8a;
  border-radius: 10px;
  position: relative;
}
.content__timeline__active {
  width: 300px;
  height: 150px;
  cursor: pointer;
  border: 2px solid #8dbc8a;
  border-radius: 10px;
  position: relative;
}
.text_timeline {
  margin-left: 2.5rem;
  font-weight: 700;
  margin-top: 22px;
}
.warpper__content {
  display: flex;
  justify-content: center;
}
.text__inbox {
  display: grid;
  margin-left: 0.5rem;
}
.font__top {
  color: #ffffff;
  font-weight: 500;
}
.font__bottom {
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
}
.icons__success {
  position: absolute;
  color: #8dbc8a !important;
  top: 11%;
  transform: translateY(-50%);
  right: 5px;
}
.square {
  /* position: absolute; */
  height: 60px;
  width: 15px;
  background-color: grey;
  margin-left: 5px;
  border-radius: 2px;
  /* top: 50%; */
  /* transform: translateY(-50%); */
}
.content__timeline p {
  margin-top: -0.5rem;
  font-size: 16px;
  font-weight: 500;
}
.content__timeline span {
  font-size: 16px;
  font-weight: 500;
}
.square_vertical {
  height: 30px;
  width: 15px;
  background-color: grey;
  margin-left: 5px;
  border-radius: 2px;
  margin-bottom: 5px;
}
.warpper__content_vertical {
  display: grid;
  justify-content: center;
}
.content__receipt {
  width: 100%;
  border: 1px solid #d4d3d4;
  border-radius: 5px;
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
}
.content__receipt p {
  margin-bottom: 0 !important;
}
.toggle__switch {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.reset__paddingBottom {
  padding-bottom: 0;
}

@media screen and (max-width: 600px) {
  .content__timeline p {
    font-size: 12px;
    font-weight: 500;
  }
  .content__timeline span {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
